import React from 'react';
import { graphql, Link } from 'gatsby';
import { useInView } from 'react-intersection-observer';
import styled from 'styled-components';

import { createSrcSetImages, createProductLink, SRCSET_SIZES } from 'lib/image';

interface CollectionItemProps {
  Handle: string;
  Title: string;
  RetailPrice?: string;
  WholesalePrice?: string;
  Sizes: string;
  MainImage: string;
  Image1?: string;
  Image2?: string;
  Image3?: string;
  Image4?: string;
  isVip?: boolean;
}

const CollectionContainer = styled.main`
  transition-delay: 100ms;
  transition-duration: 1000ms;
  transition-timing-function: ease-in-out;
  transition-property: all;
  transform: translateY(1rem);
  opacity: ${({ show = false }: { show?: boolean }): string => (show ? `1` : `0`)};
`;

const CollectionItem = ({
  Handle,
  Title,
  MainImage,
  RetailPrice,
  WholesalePrice,
  Sizes,
  isVip
}: CollectionItemProps) => {
  const link = createProductLink({ handle: Handle });
  const images = createSrcSetImages({ image: MainImage });

  const price = isVip ? RetailPrice : WholesalePrice;

  return (
    <Link to={link}>
      <div className="listing-main-inner" style={{ width: 'unset' }}>
        <figure className="snip1531">
          <img
            className="grid-view-item__image coll_prod_img lazyautosizes ls-is-cached lazyloaded"
            alt={Title}
            data-widths={`[${SRCSET_SIZES}]`}
            data-aspectratio="1.0"
            data-sizes="auto"
            data-image=""
            data-srcset={images}
            sizes="416px"
            srcSet={images}
          />

          <figcaption>
            <h3>{Title}</h3>
            <p></p>
            <dl className="price" data-price="">
              <div className="price__regular">
                <dd>
                  <span className="price-item price-item--regular" data-regular-price="">
                    <span className="money">{price}</span>
                  </span>
                </dd>
              </div>
            </dl>

            <p></p>

            <h6 className="collection_page_variant_h6">
              {Sizes.split(',').map((size: any, idx: number) => (
                <span className="product-variant__label" key={`size-${idx}`}>
                  {size}
                </span>
              ))}
            </h6>
          </figcaption>
        </figure>
      </div>
    </Link>
  );
};

export const CollectionTemplate = ({
  data: {
    allProductsCsv: { edges = [] }
  },
  isVip = false
}: any): any => {
  const [ref, inView] = useInView({ triggerOnce: true });

  const products = !isVip
    ? edges
    : edges.filter((edge: any) => isVip && edge.node.WholesaleOnly !== 'Y');

  return (
    <CollectionContainer
      ref={ref}
      show={inView}
      className="main-content1 js-focus-hidden"
      id="MainContent"
      role="main">
      <div id="shopify-section-new-collection-template" className="shopify-section">
        <div data-section-id="new-collection-template" data-section-type="collection-template">
          <section id="listing-main">
            <div className="container-fluid" id="Collection">
              <div className="row prod_coll_row">
                <div className="product_single_prod_outer">
                  {products.map((collectionItem: any, idx: number) => (
                    <CollectionItem
                      key={`collection-item-${idx}`}
                      {...{ ...collectionItem.node, isVip }}
                    />
                  ))}
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </CollectionContainer>
  );
};

export const pageQuery = graphql`
  query collectionsQuery($collection: String) {
    allProductsCsv(filter: { Collection: { glob: $collection } }) {
      edges {
        node {
          Barcode
          Handle
          WholesaleOnly
          RetailPrice
          WholesalePrice
          Published
          SKU
          Sizes
          Tags
          Title
          Collection
          MainImage
          Image1
          Image2
          Image3
          Image4
          id
        }
      }
    }
  }
`;

export default CollectionTemplate;
