import { kebabCase } from 'lodash';

export const createProductLink = ({ handle }: { handle: string }) =>
  `/products/${kebabCase(handle)}`;

export const getShopifyImageUrl = (src: string, size: number): string =>
  src && size
    ? src.replace(/\.jpg|\.png|\.gif|\.jpeg/g, (match) => `_${size}x${match}`).replace(/https:/, '')
    : src;

export const SRCSET_SIZES = [180, 360, 540, 720, 900, 1080, 1296, 1512, 1728, 2048];

export const createSrcSetImages = ({ image }: { image: string }) =>
  SRCSET_SIZES.map((size: number) => `${getShopifyImageUrl(image, size)} ${size}w`).join(', ');
